/**=====================
    25. Authentication CSS Start
==========================**/

.txt-twitter {
  color: #6fa2d8 !important;
}

.txt-linkedin {
  color: #0077b5 !important;
}

.txt-fb {
  color: #50598e !important;
}

.login-card {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: url("../../../assets/images/login/inicio_login.png");
  background-position: center;
  background-size: cover;
  padding: 30px 0;

  .logo {
    display: block;
    margin-bottom: 30px;
    text-align: center;
  }

  .btn-showcase {
    .btn {
      line-height: 1;
      padding: 10px 15px;
      margin: 0;

      &+.btn {
        margin-left: 5px;
      }

      svg {
        height: 16px;
        vertical-align: bottom;
      }
    }
  }

  .login-main {
    width: 450px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
    margin: 0 auto;
    background-color: $white;

    .theme-form {
      h4 {
        margin-bottom: 5px;
      }

      label {
        font-size: 15px;
        letter-spacing: 0.4px;
      }

      .checkbox label::before {
        background-color: $card-body-color;
        border: 1px solid #dfdfdf;
      }

      .or {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          width: 65%;
          height: 2px;
          background-color: #f3f3ff;
          top: 9px;
          z-index: 0;
          right: 0;
        }
      }

      input {
        background-color: #f3f3ff;
        transition: all 0.3s ease;

        &::-webkit-input-placeholder {
          color: $light-text;
        }

        &:hover,
        &:focus {
          border: 1px solid lighten($semi-dark, 35%);
          box-shadow: none !important;
          transition: all 0.3s ease;
        }
      }

      p {
        margin-bottom: 25px;
        font-size: 14px;
        color: $dark-gray;
      }

      .form-group {
        margin-bottom: 10px;
        position: relative;
      }

      .link {
        position: absolute;
        top: 10px;
        right: 0;
      }
    }
  }
}

.show-hide {
  position: absolute;
  top: 54px;
  right: 20px;
  transform: translateY(-50%);

  span {
    cursor: pointer;
    font-size: 13px;
    color: var(--theme-deafult);

    &.show {
      &:before {
        content: "mostrar";
      }
    }

    &:before {
      content: "ocultar";
    }
  }
}

.needs-validation {
  .invalid-feedback {
    font-size: 80%;
  }

  .show-hide {
    right: 30px;
  }

  .invalid-tooltip {
    top: 10px;
    right: 10px;
    left: unset;
  }
}



/**=====================
    25. Authentication CSS Ends
==========================**/



.columns:not(.is-desktop) {
  display: flex;
}

.columns:last-child {
  margin-bottom: -0.75rem;
}

.columns {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.75rem;
}

#btnLogin {
  width: auto;
  height: 60px;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, .25);
}

#label-microsoft {
  margin-top: 0;
  color: #616161;
  font-size: 24px;
  padding: 0 10px;
}

.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.5em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.5em - 1px);
  position: relative;
  vertical-align: top;
}

.button {
  background-color: #fff;
  border-color: #dbdbdb;
  border-width: 1px;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  text-align: center;
  white-space: nowrap;
}

.icon-microsoft {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADkSURBVHgB7ZS7DcJAEERnT0aCCiAhICKlAUQDFIFEA0iIgIyclACogDaogEJICIz8w7vY6cmntTMH+9KZtZ6s0wCGYXSDQsF7O3sMHc2hf+J630y/RNhrzbKQ+LB6LpuyKHQkjLk4LKBpiEwEiKsLtcvMn1Dm0BNMxMdEfEzEpzci4UEjuZHQGAo/8JPI5SA+aV12lKKryHj9SqrTLzTKQS7ZNIfTu0ku6REdRVAkOwxG6mwjKk+IOK5+4VntEtUTf2mK7LH6mIiPifiYiE940Bj1+HygQdVsC2WQFl1u0TEMoyV/nlBE4meTjGcAAAAASUVORK5CYII=);
  background-size: cover;
  width: 35px;
  height: 35px;
  margin-left: 15px;

  &:before {
    display: none;
  }
}