.table-card-container {
    padding-left: 0;
    padding-right: 0;
}

.cabecera {
    display: block;

    &-icon {
        width: 100%;
        text-align: center;
    }
}

.table-concesionarios {
    margin-left: 20px;
    margin-right: 20px;

    thead {
        background-color: #af4ed1;

        th {
            color: white;
        }
    }
}

.card {
    &-header {
        border-bottom: 0;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.button_filter__collapse {
    font-size: 18px;
    display: inline-block;
    padding: 5px 25px;

    @media screen and (max-width: 450px) {
        font-size: 14px;
    }

    span {
        font-weight: bold;
        padding-right: 8px;
        position: relative;
        bottom: 5px;
    }
}

.card-body-filtros {
    padding-top: 0 !important;
    padding-bottom: 15px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;

}

.detalle-leads {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
}

.buttons {
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
        text-align: center;
    }


    button {
        transform: scale(0.95);
    }
}

.table-leads {
    width: 100% !important;

    thead {
        background-color: #76C80F;

        tr {
            th {
                color: white;
                vertical-align: middle;
                text-align: center;
                padding-top: 25px;
                padding-bottom: 25px;
                font-size: 20px;
            }
        }
    }

    tbody {
        tr {
            th {
                vertical-align: middle;
                text-align: center;
                font-size: 17px;
                text-transform: uppercase;
                padding-top: 30px;
                padding-bottom: 30px;

                .badge {
                    width: 100%;
                    font-size: 16px;
                }
            }
        }
    }

}

.descripcion {
    display: none;
}

.imagen-container {
    display: none;
}

.imagen-moto {
    display: none;
}

.imagen-moto-desktop {
    width: 100%;
    display: block;
    max-width: 100px;
    text-align: center;
    margin: 0 auto;
}



@media screen and (max-width: 768px) {
    .imagen-moto-desktop {
        display: none;
    }

    .descripcion {
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
        width: fit-content;
        padding: 5px 20px;
        border-radius: 25px;
        text-decoration: underline;
        font-size: 16px;
        color: #b61212;
        font-weight: bold;
    }

    .imagen-container {
        display: block;
    }

    .imagen-moto {
        display: block;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        max-width: 300px;
        transition: all 0.3s;

        &:hover {
            transform: scale(1.2);
            transition: all 0.3s;
        }
    }

    .pulpa-datatable-responsive {
        margin: 0 auto;
        width: 100% !important;

        thead {
            display: none;
        }

        tbody {
            .tr-mobile {
                display: block;
                margin-bottom: 25px;
                padding-top: 15px;
                padding-bottom: 15px;

                th {
                    display: block;
                    width: 100%;
                    margin-bottom: 10px;
                    padding-top: 5px;
                    padding-bottom: 15px;

                    @media screen and (max-width: 768px) {
                        font-size: 20px;
                    }


                    .badge {
                        width: auto;
                        max-width: 50%;
                        margin: 0 auto;
                        padding: 5px 10px;
                        display: block;

                        @media screen and (max-width: 768px) {
                            font-size: 14px;
                            width: fit-content;
                            min-width: auto;
                            padding: 10px 30px;
                        }
                    }

                    i.lead-option {
                        font-size: 20px;

                        @media screen and (max-width: 768px) {
                            font-size: 30px;
                        }
                    }
                }
            }
        }
    }
}

.div-button_filter {
    @media screen and (max-width: 768px) {
        text-align: center;
    }
}

.contenedor-leads {
    max-width: 100%;
}

.nombre-estado-cliente {
    font-size: 25px;

    &.small{
        font-size: 20px;
    }

    @media screen and (max-width: 768px) {
        font-size: 18px;
    }

    @media screen and (max-width: 550px) {
        font-size: 15px;

        &.small{
            font-size: 12px;
        }
    }
}

.number-estado-cliente {
    font-size: 35px;

    @media screen and (max-width: 768px) {
        font-size: 24px;
    }

    @media screen and (max-width: 450px) {
        font-size: 15px;
    }
}

#make_filter {
    font-size: 18px;
    display: inline-block;
    padding: 5px 25px;

    @media screen and (max-width: 450px) {
        font-size: 14px;
    }

}

.resumen-leads {
    padding-top: 25px;

    @media screen and (max-width: 768px) {
        width: 100%;
        padding: 0;
        margin: 0;
    }
}


.mobile-detail {
    font-size: 15px;
}

.checkbox-pulpa {
    display: block;
    margin: 0 auto;
    margin-top: 15px;
}